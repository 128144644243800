<template>
    <div></div>
</template>

<script>
export default {
    name: 'Tracker',
    watch: {
        '$route.path': function() {
        }
    },
    mounted () {
    },
}
</script>